import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import toastPreviewData from '../../../../data/previews/toast.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const Toast = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Toast;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToastOpen: false,
    };
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Toast"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Notifications"
          tierThree="Toast"
        />
        <V5Notice
          componentName="Toast"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/notifications-toast--docs"
        />
        <Paragraph>
          If the message would be more effective <em>before</em> the task,
          consider a{' '}
          <Link href="/components/notifications/notice" isDesignCodeLink>
            notice
          </Link>{' '}
          or{' '}
          <Link href="/components/notifications/note" isDesignCodeLink>
            note
          </Link>{' '}
          instead.
        </Paragraph>
        <PageNavigation
          links={[
            'Type',
            'States',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Type">
          <ComponentPreview
            name="ToastType"
            layout="default"
            previewData={toastPreviewData.types}>
            <Button
              buttonType="primary"
              onClick={() => this.setState({ isToastOpen: true })}>
              Trigger Toast
            </Button>
            {this.state.isToastOpen && (
              <Toast
                text="A toast, ya know?"
                onDismiss={() => this.setState({ isToastOpen: false })}
              />
            )}
          </ComponentPreview>
        </Section>

        <Section title="States">
          <SectionSubhead>Web</SectionSubhead>
          <Paragraph>
            All toasts appear at 100% and immediately fade to 90% for a
            four-second hold before automatically dismissing with a three-second
            fadeout.
          </Paragraph>
          <Paragraph>
            The auto-dismiss timeline gives users a full seven seconds to hover
            and hold the toast at 100%.
          </Paragraph>
          <Paragraph>
            <strong>Critical toasts do not auto-dismiss</strong>. We need the
            user to acknowledge the problem being communicated.
          </Paragraph>

          <SectionSubhead>Apple</SectionSubhead>
          <Paragraph>
            Toasts will auto-dismiss after three seconds if you choose to make
            it timed. If you don’t, the toast must be deliberately dismissed
            with a single tap or swipe.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Feedback</SectionSubhead>
          <Paragraph>
            Every toast provides a certain type of feedback, emphasized by a{' '}
            <Link
              href="/guidelines/colors/utility/design#Options"
              isDesignCodeLink>
              color
            </Link>{' '}
            and{' '}
            <Link href="/components/icons/feedback/design">feedback icon</Link>.
          </Paragraph>
          <DontDo
            dontText="interchange feedback colors and icons."
            doText="match feedback to the message type."
            imgFilename="toast-feedback"
          />

          <SectionSubhead>Action Buttons</SectionSubhead>
          <Paragraph>
            A dismiss is included on all toasts, but you can also include one
            additional action styled as a small subtle button.
          </Paragraph>
          <DontDo
            dontText="add an irrelevant action just to have one."
            doText="make the action a clear next step relevant to the content."
            imgFilename="toast-action"
          />

          <Paragraph>
            <strong>All critical toasts require an action</strong>, usually
            Learn More.
          </Paragraph>
          <DontDo
            dontText="abandon the user to solve the problem themselves."
            doText="help them understand what went wrong and offer a solution."
            imgFilename="toast-criticalaction"
          />

          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            Position is handled for you, regardless of platform. On web, the
            toast will appear in the bottom right corner. On Apple, it’s
            positioned along the top of the screen.
          </Paragraph>
          <Paragraph>
            For the time being, stick to displaying one toast at a time.
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Case & Punctuation</SectionSubhead>
          <Paragraph>
            Toasts follow the{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product#Non-Label%20UI%20Text">
              non-label text
            </Link>{' '}
            guidelines. They use sentence case with appropriate punctuation.
          </Paragraph>
          <DontDo
            dontText="yell at the user with caps lock or crazy punctuation."
            doText="use complete sentences followed by a period."
            imgFilename="toast-casepuctuation"
          />

          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            Keep the content readable at a glance—no more than 60 characters
            long. Put keywords at the front.
          </Paragraph>
          <DontDo
            dontText="overwhelm the user with too many details."
            doText="keep things brief with only the most important info."
            imgFilename="toast-contentlength"
          />

          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            Given a toast’s size and the scope of information provided, it
            should never have a title.
          </Paragraph>
          <DontDo
            dontText="take up valuable real estate with unnecessary copy."
            doText="load the content with keywords to make scanning easy."
            imgFilename="toast-titles"
          />

          <SectionSubhead>Action Buttons</SectionSubhead>
          <Paragraph>
            Try limiting the action to a single verb, ideally one of the
            following five: <strong>Undo</strong>, <strong>Retry</strong>,{' '}
            <strong>View</strong>, <strong>Share</strong> or{' '}
            <strong>Learn More</strong>.
          </Paragraph>
          <DontDo
            dontText="make button labels complete sentences."
            doText="keep things simple with a single verb."
            imgFilename="toast-actionbuttons"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Notifications / Toast" />

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
